var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-workers-sidebar","bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Добавить ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitWorkers($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Дата","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Дата","label-for":"datepicker-placeholder"}},[_c('b-form-datepicker',{attrs:{"id":"datepicker-placeholder","placeholder":"Выберите дату","rules":"required","state":errors.length > 0 ? false:null},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Имя сотрудника","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Имя сотрудника","label-for":"full-name"}},[_c('b-form-select',{attrs:{"id":"full-name","autofocus":"","multiple":"","trim":"","placeholder":"Имя сотрудника","state":errors.length > 0 ? false : null},model:{value:(_vm.worker_id),callback:function ($$v) {_vm.worker_id=$$v},expression:"worker_id"}},[_vm._v(" - "),_vm._l((_vm.workersOptions),function(worker,index){return _c('b-form-select-option',{key:index,attrs:{"value":worker.id}},[_vm._v(" "+_vm._s(worker.full_name)+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{ required: true, regex: _vm.floatRegex },"name":"Количество отработанных часов"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Количество отработанных часов","label-for":"hours"}},[_c('b-form-input',{attrs:{"id":"hours","state":errors.length > 0 ? false:null,"placeholder":"Количество отработанных часов"},model:{value:(_vm.work_hours),callback:function ($$v) {_vm.work_hours=$$v},expression:"work_hours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',[_c('b-form-select',{attrs:{"options":[
                    {value: 'Часы', text: 'Сколько отработал за день'},
                    {value: 'От', text: 'Отпуск'},
                    {value: 'ДО', text: 'Административный отпуск'},
                    {value: 'ОЖ', text: 'Отпуск по уходу за ребенком после 1.5 лет'},
                    {value: 'Желый', text: 'Взять заявление с сотрудника'}
                ]},model:{value:(_vm.work_status),callback:function ($$v) {_vm.work_status=$$v},expression:"work_status"}})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Сохранить ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.isAddWorkersScheduleSidebarActive = false}}},[_vm._v(" Отмена ")])],1)],1)]}}],null,true)})]}}]),model:{value:(_vm.isAddWorkersScheduleSidebarActive),callback:function ($$v) {_vm.isAddWorkersScheduleSidebarActive=$$v},expression:"isAddWorkersScheduleSidebarActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }