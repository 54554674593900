<template>
  <b-sidebar
      id="add-new-workers-sidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      v-model="isAddWorkersScheduleSidebarActive"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="submitWorkers"
            @reset.prevent="resetForm"
        >
          <validation-provider
              #default="{ errors }"
              name="Дата"
              rules="required"
          >
            <b-form-group
                label="Дата"
                label-for="datepicker-placeholder"
            >
              <b-form-datepicker
                  v-model="date"
                  id="datepicker-placeholder"
                  placeholder="Выберите дату"
                  rules="required"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="{ errors }"
              name="Имя сотрудника"
              rules="required"
          >
            <b-form-group
                label="Имя сотрудника"
                label-for="full-name"
            >

              <b-form-select
                  id="full-name"
                  autofocus
                  v-model="worker_id"
                  multiple
                  trim
                  placeholder="Имя сотрудника"
                  :state="errors.length > 0 ? false : null"
              >
                -                <b-form-select-option :value="worker.id" :key="index" v-for="(worker, index) in workersOptions">
                {{worker.full_name}}
              </b-form-select-option>
              </b-form-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="{ errors }"
              :rules="
{ required: true, regex: floatRegex }"
              name="Количество отработанных часов"
          >
            <b-form-group
                label="Количество отработанных часов"
                label-for="hours"
            >
              <b-form-input
                  v-model="work_hours"
                  id="hours"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Количество отработанных часов"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <b-form-group>
            <b-form-select
                v-model="work_status"
                :options="[
                    {value: 'Часы', text: 'Сколько отработал за день'},
                    {value: 'От', text: 'Отпуск'},
                    {value: 'ДО', text: 'Административный отпуск'},
                    {value: 'ОЖ', text: 'Отпуск по уходу за ребенком после 1.5 лет'},
                    {value: 'Желый', text: 'Взять заявление с сотрудника'}
                ]"
            >
            </b-form-select>
          </b-form-group>


<!--          <b-form-group-->
<!--              label="Почему не работал?"-->
<!--              label-for="whyDidntWork"-->
<!--              v-if="work_hours === '0'"-->
<!--          >-->
<!--            <b-form-input-->
<!--                v-model="notWorkingReason"-->
<!--                id="whyDidntWork"-->
<!--                placeholder="Почему не работал?"-->
<!--            />-->
<!--          </b-form-group>-->


          <div class="d-flex mt-2">
            <b-button
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Сохранить
            </b-button>
            <b-button
                type="button"
                variant="outline-secondary"
                @click="isAddWorkersScheduleSidebarActive = false"

            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>


import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import BCardCode from "@core/components/b-card-code/BCardCode";
import { floatRegex } from '@/libs/regexes'

export default {
  components: {
    BCardCode,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    flatPickr,
    BFormSelectOption,
    vSelect,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: ['no_of_days'],
  // model: {
  //   prop: 'isAddWorkersScheduleSidebarActive',
  //   event: 'update:is-add-new-workers-sidebar-active',
  // },
  // props: {
  //   isAddWorkersScheduleSidebarActive: {
  //     type: Boolean,
  //     required: true,
  //   },
  // },
  data() {
    return {
      isAddWorkersScheduleSidebarActive: false,
      workersOptions: [],
      selectedWorker: null,
      worker_id: '',
      date: '',
      work_hours: '0',
      work_status: '',
      notWorkingReason: '',
      required,
      alphaNum,
      email,
      floatRegex
    }
  },

  created() {
    this.$store.dispatch('info/getWorkers')
        .then(() => {
          this.workersOptions = this.workers
        })
  },
  computed: {
    workers() {
      return this.$store.state.info.workersList
    }
  },
  methods: {
    submitWorkers() {
      this.$store.dispatch('info/scheduleCreate',{
        worker_id: this.worker_id,
        date: this.date,
        work_hours: this.work_hours.replace(',', '.'),
        work_status: this.work_status,
      }).then(() => {
        this.$toast.success('Сохранён')
        this.$store.dispatch('info/getWorkersScheduler', {date: this.no_of_days.format('YYYY-MM')})
        this.isAddWorkersScheduleSidebarActive = false
      }).catch(err => {
        this.$toast.error('Не удалось сохранить')
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-workers-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
