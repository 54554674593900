<template>
  <div>
    <list-workers-schedule-add-new
      ref="scheduleSidebar"
      :no_of_days="no_of_days"
    />
    <b-col cols="2" class="mb-1" v-if="$can('create', 'schedule')">
      <b-button
        variant="primary"
        @click="showSidebar"
      >
        <span class="text-nowrap">Добавить</span>
      </b-button>

      <b-button variant="primary" style="margin-left: 10px;" @click="print('list-workers-schedule')">Распечатать
      </b-button>

    </b-col>
    <b-col class="d-flex mb-1">
      <b-button
        class="mr-1"
        variant="outline-primary"
        @click="fetchPrevMonth"
      >
        <span class="text-nowrap"><b-icon-arrow-left class="mr-1" variant="primary"
        ></b-icon-arrow-left>Пред. месяц</span>
      </b-button>
      <b-button
        variant="outline-primary"
        @click="fetchNextMonth"
      >
        <div class="text-nowrap">След. месяц
          <b-icon-arrow-right class="ml-1" variant="primary"></b-icon-arrow-right>
        </div>
      </b-button>
    </b-col>
    <div id="list-workers-schedule">
      <b-col class="col-12 mb-1 text-center">
        <strong :style="{color: isDark ? '#ffffff' : '#6e6b7b'}" class="text-nowrap"
                style="font-size: 15px;font-weight: 500"
        >{{ no_of_days.format('MMMM YYYY') }}</strong>
      </b-col>
      <div class="list-view-row d-flex w-100 justify-content-between border-top">
        <div class="list-visit-day border-left">№</div>
        <div class="list-visit-child-name border-right border-left">
          <div class="text-center">ФИО Работника</div>
        </div>
        <div class="list-visit-day head border-right" :key="index" v-for="(day, index) in no_of_days.daysInMonth()">
          <div>{{ day }}</div>
        </div>
        <div :class="{
            'list-visit-day': true,
              'head': true,
                'border-right': true
          }"
        >
          <div>Время работы</div>
        </div>
      </div>
      <div
        :class="{
      'list-view-row': true,
       'd-flex': true,
        'w-100': true,
         'justify-content-between': true,
          'border-top': true,
            'border-bottom': (items.length - 1) === index
          }"
        :key="index" v-for="(worker, index) in items"
      >
        <div class="list-visit-day border-left">{{ index+1 }}</div>
        <div class="list-visit-child-name border-right border-left">
          <div>{{ worker.name }}</div>
        </div>
        <div
          :class="{'list-visit-day': true, 'value': true, 'border-right': true}"
          :key="index" v-for="(day, index) in no_of_days.daysInMonth()"
        >
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret v-if="!!checkVisit(day, worker)">
            <template #button-content>
              <span style="color:#6e6b7b;">{{ checkVisit(day, worker) }}</span> <span class="sr-only">status</span>
            </template>
            <b-dropdown-item :key="status.value" v-for="(status) in visitStatus" disabled
            ><span style="color: #6e6b7b;">{{ status.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          :class="{'list-visit-day': true, 'value': true, 'border-right': true}"
        >
          <div>{{ sumTime(index) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BPagination, BRow,
  BTable,
  BIconArrowRight,
  BIconArrowLeft, BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import ListWorkersAddNew from '@/views/workers/ListWorkersAddNew.vue'
import ListWorkersScheduleAddNew from '@/views/workers/ListWorkersScheduleAddNew'
import moment from 'moment'
import emojiStatus, { emojiStatusForWorkers } from '@/libs/emoji-status'

export default {
  name: 'ListWorkersSchedule',
  components: {
    ListWorkersScheduleAddNew,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,
    BTable,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BPagination,
    BRow,
    BCol,
    ListWorkersAddNew,
    BIconArrowRight,
    BIconArrowLeft
  },
  data() {
    const d = moment()
    d.set('date', 1)
    const month = d.get('month')
    const year = d.get('year')
    return {
      visitStatus: [
        {
          value: 'Желый',
          text: `${emojiStatusForWorkers('Желый')} Взять заявление с сотрудника`
        },
        {
          value: 'От',
          text: `${emojiStatusForWorkers('От')} Отпуск`
        },
        {
          value: 'ОЖ',
          text: `${emojiStatusForWorkers('ОЖ')} Отпуск по уходу за ребенком после 1.5 лет`
        },
        {
          value: 'ДО',
          text: `${emojiStatusForWorkers('ДО')} Административный`
        },
        {
          value: 'Б',
          text: `${emojiStatusForWorkers('Б')} Больничный`
        },
      ],
      createUser: '',
      user: '',
      date: '',
      status: true,
      userId: 1,
      attendanceId: 1,
      add_days: [{
        name: 'gsdjg\';;\'',
        dates: [
          true, false
        ]
      }, {
        name: '342g\';;\'',
        dates: [
          true, false
        ]
      }],
      users: [{
        id: 1,
        name: 'lorem ipsum',
        attendances: []
      }],
      month: month,
      year: year,
      no_of_days: d
    }
  },
  computed: {
    items() {
      return this.$store.state.info.workersSchedulerList
    }
  },
  created() {
    this.fetchData(1)
  },
  methods: {
    sumTime(workerIndex) {
      const filteredDates = []
      for (const dateObj of this.$store.state.info.workersSchedulerList[workerIndex].dates) {
        if (!filteredDates.find(date => date.date === dateObj.date)) {
          filteredDates.push(dateObj)
        }
      }

      console.log({ filteredDates })
      return filteredDates.reduce((acc, cur) => cur.status === 'Часы' || !cur.status ? acc + cur.count_hours : acc, 0)
        .toFixed(2)
      // return this.$store.state.info.countVisits[worker.id]
    },

    checkVisit(day, worker) {
      const date = this.no_of_days
      date.set('date', day)
      const visit = worker.dates.find(d => moment(d.date)
        .isSame(date, 'day')) || null
      if (visit && (visit.status === 'Часы' || !visit.status)) {
        return visit.count_hours
      }

      return visit ? emojiStatusForWorkers(visit.status) : ''
    },
    showSidebar() {
      if (!this.$refs.scheduleSidebar.isAddWorkersScheduleSidebarActive) {
        this.$refs.scheduleSidebar.isAddWorkersScheduleSidebarActive = true
      }
    },
    fetchPrevMonth() {
      this.no_of_days.subtract(1, 'month')
      this.fetchData(1)
    },
    fetchNextMonth() {
      this.no_of_days.add(1, 'month')
      this.fetchData(1)
    },
    fetchData(page) {
      // const params = {
      //   limit: this.limit,
      //   offset: page === 1 ? 0 : (page * this.limit) - this.limit,
      // }
      // if (this.filter !== '') {
      //   params.search = this.filter
      // }
      const params = {
        date: this.no_of_days.format('YYYY-MM')
      }
      this.$store.dispatch('info/getWorkersScheduler', params)
    },
    filterData() {
      if (this.filter !== '') {
        this.fetchData(1)
      } else {
        this.fetchData(1)
      }
    },
    pageChanged(page) {
      this.fetchData(page)
    },
    onRowSelected(items) {
      this.$emit('items-selected', items)
    },
    async print(id) {
      await this.$htmlToPaper(id)
    }
  }
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/components/table.scss';
</style>
