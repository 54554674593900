<template>
  <b-sidebar
    id="add-new-workers-sidebar"
    v-model="isAddWorkersSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSidebar"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submitWorkers"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Имя пользователя"
            label-for="username"
          >

            <b-form-input
              id="username"
              v-model="username"
              autofocus
              trim
              placeholder="Имя пользователя"
            />
          </b-form-group>

          <b-form-group
            label="Имя"
            label-for="first-name"
          >

            <b-form-input
              id="first-name"
              v-model="first_name"
              autofocus
              trim
              placeholder="Имя"
            />
          </b-form-group>

          <b-form-group
            label="Фамилия"
            label-for="last-name"
          >

            <b-form-input
              id="last-name"
              v-model="last_name"
              autofocus
              trim
              placeholder="Фамилия"
            />
          </b-form-group>

          <b-form-group
            label="Адрес электронной почты"
            label-for="email"
          >

            <b-form-input
              id="email"
              v-model="emailInput"
              autofocus
              trim
              placeholder="Адрес электронной почты"
            />
          </b-form-group>

          <b-form-group
            label="Отчество"
            label-for="middle-name"
          >

            <b-form-input
              id="middle-name"
              v-model="middle_name"
              autofocus
              trim
              placeholder="Отчество"
            />
          </b-form-group>

          <b-form-group
            label="Паспорт"
            label-for="passport"
          >

            <b-form-input
              id="passport"
              v-model="passport"
              autofocus
              trim
              placeholder="Паспорт"
            />
          </b-form-group>

          <b-form-group
            label="Снил"
            label-for="snil"
          >

            <b-form-input
              id="snil"
              v-model="snil"
              autofocus
              trim
              placeholder="Снил"
            />
          </b-form-group>

          <b-form-group
            label="Номер телефона"
            label-for="phone-number"
          >
            <b-form-input
              id="phone-number"
              v-model="phone_number"
              autofocus
              trim
              placeholder="Номер телефона"
            />
          </b-form-group>

          <b-form-group>
            <label for="birth-date">Дата рождения</label>
            <b-form-datepicker
              id="birth-date"
              v-model="birth_date"
              show-decade-nav
              placeholder="Дата не выбрана"
            />
          </b-form-group>

          <b-form-group
            label="Роль"
            label-for="roles"
          >

            <b-form-select
              v-model="roles"
              :options="[
                {value: 'admin', text: 'Администратор'},
                {value: 'nurse', text: 'Мед. сестра'},
                {value: 'director', text: 'Директор'},
                {value: 'worker', text: 'Работник'},
              ]"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="isAddWorkersSidebarActive = false"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {
  BSidebar,
  BForm,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormSelect,
  BFormSelectOption, Cleave
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import BCardCode from "@core/components/b-card-code/BCardCode"

export default {
  components: {
    BCardCode,
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    flatPickr,
    BFormSelectOption,
    Cleave,
    vSelect,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      groupsOptions: [],
      isAddWorkersSidebarActive: false,
      username: '',
      worker_id: '',
      first_name: '',
      last_name: '',
      emailInput: '',
      middle_name: '',
      passport: '',
      groups: [],
      snil: '',
      phone_number: '',
      birth_date: '',
      roles: '',
      date: '',
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    workerToEdit() {
      return this.$store.state.info.workerToEdit
    },
  },
  watch: {
    workerToEdit: {
      deep: true,
      handler(worker) {
        this.first_name = worker.first_name
        this.last_name = worker.last_name
        this.emailInput = worker.email
        this.middle_name = worker.middle_name
        this.passport = worker.passport
        this.snil = worker.snil
        this.phone_number = worker.phone_number
        this.birth_date = worker.birth_date
        this.roles = worker.roles
        this.username = worker.username
      }
    },
    isAddWorkersSidebarActive(val) {
      if (val === false) {
        this.resetForm()
      }
    }
  },
  created() {
    this.$store.dispatch('info/getChildrenGroups')
      .then(() => {
        this.groupsOptions = this.$store.state.info.childrenGroups
      })
  },
  destroyed() {
    this.$store.commit('info/emptyWorkerToEdit')
  },
  methods: {
    inviteWorker(id) {
      this.$store.dispatch("info/inviteWorker", { id }).then(
        // eslint-disable-next-line consistent-return
        content => {
          console.log(content)
          const d = content.data
          // eslint-disable-next-line prefer-promise-reject-errors
          if (!d.mail_sent) return Promise.reject("email не был отправлен")
          this.$toast.success("email с паролем отправлен")
        }
      ).catch(
        err => {
          this.$toast.error(`${err}`, { timeout: 2500 })
        }
      )
    },
    submitWorkers() {
      let savedId
      const payload = {
        username: this.username,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.emailInput,
        middle_name: this.middle_name,
        passport: this.passport,
        snil: this.snil,
        phone_number: this.phone_number,
        birth_date: this.birth_date,
        roles: this.roles,
      }
      if (this.workerToEdit && this.workerToEdit.hasOwnProperty('id')) {
        payload.id = this.workerToEdit.id
        this.$store.dispatch('info/editWorker', payload).then(() => {
          this.$toast.success('Сохранён', { timeout: 2000 })
          this.isAddWorkersSidebarActive = false
          this.resetForm()
        }).catch(err => {
          console.error(err)
          this.$toast.error('Не удалось сохранить', { timeout: 2000 })
        })
      } else {
        this.$store.dispatch('info/addWorker', payload).then(res => {
          savedId = res.data.id
          this.$toast.success('Сохранён', { timeout: 2000 })
          this.isAddWorkersSidebarActive = false
          this.resetForm()
        }).then(
          () => {
            this.inviteWorker(savedId)
          }
        ).catch(err => {
          console.error(err)
          this.$toast.error('Не удалось сохранить', { timeout: 2000 })
        })
      }
    },
    resetForm() {
      this.first_name = ''
      this.last_name = ''
      this.emailInput = ''
      this.middle_name = ''
      this.passport = ''
      this.snil = ''
      this.phone_number = ''
      this.birth_date = ''
      this.roles = ''
      this.username = ''
      this.$store.commit('info/emptyWorkerToEdit')
    },
    hideSidebar() {
      this.isAddWorkersSidebarActive = false
      this.resetForm()
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-workers-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
